import React, {
    useState,
    createElement,
    useEffect,
    useMemo,
    useCallback,
} from 'react'
import { CheckCircle, Error } from '@mui/icons-material'
import {
    Box,
    Grid,
    Typography,
    Card,
    Button,
    CardContent,
    CircularProgress,
    Container,
    useMediaQuery,
} from '@mui/material'
import { isEmpty } from 'lodash'
import { connect, useSelector } from 'react-redux'
import {
    SearchBar,
    genTenantSearchIndex,
} from '../../components/InstaSearchBar'
import { CustomImage, cleanUserName } from '../../components/componentHelpers'
import { formatDate, nextSyncTime } from '@thefront/pandipackV2'
import { useThemeConfig } from '../../themeConfig'
import TenantCornerMenu from './TenantCornerMenu'
import SyncButton from '../../components/SyncButton'
import { useTheme } from '@mui/material/styles'
import { useLocation } from 'react-router-dom'
import { useGetList, useDataProvider, useRedirect } from 'react-admin'

const classes = {
    container: (theme) => ({
        flex: theme?.marketplaceSettings?.fullWidthMarketplace ? '1' : 'none',
        width: '776px',
        margin: '0 0 40px 0',
        [theme.breakpoints.up('sm')]: {
            width: '896px',
        },
        [theme.breakpoints.up('md')]: {
            width: '960px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '1120px',
        },
        [theme.breakpoints.up('xl')]: {
            width: '1280px',
        },
        [theme.breakpoints.up('xxl')]: {
            width: '1608px',
        },
    }),
    header: {
        margin: '0 0 40px 0',
        width: '100%',
    },
    installedAppsDiv: { margin: '40px 0px 20px' },
    leftAlign: {
        textAlign: 'left',
    },
    circularProgressStyle: {
        margin: '5% 50%',
    },
    circularProgressTenant: {
        margin: '80px',
    },
    title1: {
        textTransform: 'capitalize',
        padding: '0 0 10px 0',
    },
    divider: {
        marginBottom: '44px',
    },
    headerButton: {
        width: '164px',
    },
    recommendListDiv: {
        marginTop: '75px',
    },
    tenantCard: (theme) => ({
        height: '236px',
        verticalAlign: 'top',
        width: '372px',
        [theme.breakpoints.up('xs')]: {
            width: '372px',
        },
        [theme.breakpoints.up('sm')]: {
            width: '432px',
        },
        [theme.breakpoints.up('md')]: {
            width: '309px',
        },
        [theme.breakpoints.up('lg')]: {
            width: '350px',
        },
        [theme.breakpoints.up('xl')]: {
            width: '405px',
        },
        display: 'inline-block',
    }),
    tenantCardTop: {
        display: 'flex',
        padding: '0px 24px',
    },
    tenantCardContent: {
        padding: '20px 0px',
        minHeight: '118px',
    },
    integrationName: {
        paddingBottom: '10px',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    tenantName: {
        height: '23px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        paddingBottom: '10px',
        whiteSpace: 'nowrap',
    },
    inFlex: (theme) => ({
        height: '20px',
        display: 'inline-flex',
        [theme.breakpoints.down('xl')]: {
            width: '256px',
        },
        verticalAlign: 'top',
    }),
    syncLabel: {
        textTransform: 'capitalize',
        marginRight: '10px',
        fontSize: '14px',
        whiteSpace: 'nowrap',
    },
    syncTime: {
        fontSize: '14px',
    },
    buttonStyle: {
        minWidth: '120px',
        height: '36px',
        padding: '10px 16px',
        border: '1px solid rgba(0,0,0,0.12)',
        radius: '6px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
    },
    imageStyle: (theme) => ({
        borderRadius: '4px',
        objectFit: 'contain',
        marginTop: '16px',
        height: '60px',
        width: '60px',
        [theme.breakpoints.only('md')]: {
            width: '48px',
            height: '48px',
        },
    }),
    cardButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '0px 24px',
    },
    status: {
        textTransform: 'uppercase',
        display: 'inline-flex',
        fontWeight: '500',
        alignItems: 'center',
        columnGap: '8px',
    },
    activeStatus: {
        color: '#7ED321',
    },
    errorStatus: {
        color: '#B00020',
    },
    setupStatus: {
        color: 'rgba(0,0,0,0.6)',
    },
}

// TODO: this is missing an element in the latest spec
// (https://pandium.invisionapp.com/spec/cke1l388j03ft011001ormzar/inspect/691925384)
// that shows an error messasge in the error state, and a more prominent link to the logs.
// The error can come from status.last_run.phase
// challenge is that this changes the hight of the card (and all cards on that row)
export const TenantCard = ({ tenant, redirect, dateFormat }) => {
    const theme = useThemeConfig()
    const { marketplaceSettings } = theme.configs
    const handleReconnectClick = () => {
        redirect(`/tenants/${tenant.id}/edit/connection-settings`)
    }

    const lastSuccessfulRunTime = formatDate(
        tenant?.status?.last_successful_run?.completionTime,
        false,
        dateFormat
    )
    const connected = tenant?.status.auth.connected

    // disable sync button during sync. we will set this to true
    // when the button is clicked, and revert it to false when
    // tenant.status.last_run is updated
    const [syncInProgress, setSyncInProgress] = useState(
        // eslint-disable-next-line sentence-case/sentence-case
        tenant.status.current_run?.phase === 'In Progress'
    )

    useEffect(() => {
        if (syncInProgress) {
            setSyncInProgress(false)
        }
        // eslint-disable-next-line
    }, [tenant.status?.last_run?.completionTime])

    // setupStatus is true if there has never been a run
    const setupStatus = !tenant.status?.last_run

    // errorStatus is true if the last run failed
    const errorStatus =
        !setupStatus && tenant.status?.last_run?.phase?.startsWith('Failed')

    const splitName = tenant.name.split('--')
    const tenantName = splitName[splitName.length - 1]
    const [icon, message, classname, label] = errorStatus
        ? [<Error />, 'Error', classes.errorStatus, 'tenant error']
        : setupStatus
        ? [<Error />, 'Setup', classes.setupStatus, 'tenant setup required']
        : [<CheckCircle />, 'Active', classes.activeStatus, 'tenant active']

    return (
        <Grid item>
            <Card sx={classes.tenantCard} className={'pandium-installed-card'}>
                <Box sx={classes.leftAlign}>
                    <Container spacing={2} sx={classes.tenantCardTop}>
                        <Grid item xs={10}>
                            <Box sx={classes.tenantCardContent}>
                                <Typography
                                    variant="h5"
                                    sx={classes.integrationName}
                                >
                                    {tenant.integration.long_name}
                                </Typography>
                                <Typography
                                    className={'pandium-tenant-name'}
                                    sx={classes.tenantName}
                                >
                                    {tenantName}
                                </Typography>
                                <Box
                                    sx={{
                                        ...classes.status,
                                        ...classname,
                                    }}
                                    aria-label={label}
                                >
                                    {icon}
                                    <span>{`${message}`}</span>
                                </Box>
                                <br />
                                <Box sx={classes.inFlex}>
                                    <Typography
                                        color={'textSecondary'}
                                        component="span"
                                        sx={classes.syncLabel}
                                    >
                                        Last run:
                                    </Typography>
                                    <Typography
                                        color={'textSecondary'}
                                        noWrap={true}
                                        sx={classes.syncTime}
                                    >
                                        {lastSuccessfulRunTime
                                            ? lastSuccessfulRunTime
                                            : 'N/A'}
                                    </Typography>
                                </Box>
                                <br />
                                <Box sx={classes.inFlex}>
                                    <Typography
                                        color={'textSecondary'}
                                        component="span"
                                        sx={classes.syncLabel}
                                    >
                                        {connected ? 'Next run:' : ''}
                                    </Typography>
                                    <Typography
                                        color={'textSecondary'}
                                        sx={classes.syncTime}
                                        noWrap={true}
                                    >
                                        {connected
                                            ? nextSyncTime(
                                                  tenant.schedule,
                                                  tenant.paused,
                                                  false,
                                                  dateFormat
                                              )
                                            : ''}
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={2}>
                            <CustomImage
                                src={
                                    tenant.integration.marketplace_settings
                                        .secondaryLogo
                                }
                                sx={classes.imageStyle}
                            />
                        </Grid>
                    </Container>
                </Box>
                <Box sx={classes.cardButtons}>
                    {connected ? (
                        <SyncButton
                            className={'pandium-sync-button'}
                            sx={classes.buttonStyle}
                            disabled={syncInProgress}
                            color={'primary'}
                            mode={setupStatus ? 'init' : 'normal'}
                            shouldNavigate={false}
                            iconButton={false}
                            record={tenant}
                            onClick={() => setSyncInProgress(true)}
                        >
                            {setupStatus
                                ? marketplaceSettings.initSyncButton ??
                                  'Init sync'
                                : marketplaceSettings.syncButton ?? 'Sync now'}
                        </SyncButton>
                    ) : (
                        <Button
                            className={'pandium-connect-button'}
                            sx={classes.buttonStyle}
                            color={'primary'}
                            onClick={handleReconnectClick}
                            children={
                                <span style={{ color: '#B00020' }}>
                                    {setupStatus
                                        ? marketplaceSettings.connectButtonLabel ??
                                          'Connect'
                                        : marketplaceSettings.reconnectButtonLabel ??
                                          'Reconnect'}
                                </span>
                            }
                            variant={'outlined'}
                        />
                    )}
                    <TenantCornerMenu
                        supportUrl={
                            tenant.integration.marketplace_settings.supportURL
                        }
                        id={tenant.id}
                        redirect={redirect}
                    />
                </Box>
            </Card>
        </Grid>
    )
}

const InstalledExternalIntegrationCard = ({ integration, redirect }) => {
    const handleClick = () => {
        redirect(`/tenants/create/${integration.id}`)
    }

    return (
        <Grid item>
            <Card sx={classes.tenantCard} className={'pandium-installed-card'}>
                <Box sx={classes.leftAlign}>
                    <Container spacing={2} sx={classes.tenantCardTop}>
                        <Grid item xs={10}>
                            <CardContent sx={classes.tenantCardContent}>
                                <Typography
                                    sx={classes.integrationName}
                                    variant="h5"
                                >
                                    {integration.longName}
                                </Typography>
                                <Typography
                                    sx={classes.tenantName}
                                ></Typography>
                                <Box
                                    sx={{
                                        ...classes.status,
                                        ...classes.activeStatus,
                                    }}
                                    aria-label={'tenant active'}
                                >
                                    <CheckCircle />
                                    <span>ACTIVE</span>
                                </Box>

                                <br />
                                <Box sx={classes.inFlex}>
                                    <Typography
                                        color={'textSecondary'}
                                        component="span"
                                        sx={classes.syncLabel}
                                    ></Typography>
                                </Box>
                                <br />
                                <Box sx={classes.inFlex}>
                                    <Typography
                                        color={'textSecondary'}
                                        component="span"
                                        sx={classes.syncLabel}
                                    ></Typography>
                                </Box>
                            </CardContent>
                        </Grid>
                        <Grid item xs={2}>
                            <CustomImage
                                src={
                                    integration.marketplaceSettings
                                        .secondaryLogo
                                }
                                sx={classes.imageStyle}
                            />
                        </Grid>
                    </Container>
                </Box>
                <Box sx={classes.cardButtons}>
                    <Button
                        className={'pandium-sync-button'}
                        sx={classes.buttonStyle}
                        color={'primary'}
                        onClick={handleClick}
                        children={'LAUNCH'}
                    />
                </Box>
            </Card>
        </Grid>
    )
}

/**
 *
 * @param records
 * @param redirect
 * @param dateFormat
 */
const renderTenantCards = (records, redirect, dateFormat) => {
    const tiles = []
    records.forEach((record) => {
        // Don't show tenants whose integration has been deleted
        if (record.integration?.id) {
            tiles.push(
                createElement(TenantCard, {
                    key: record.id,
                    tenant: record,
                    redirect: redirect,
                    dateFormat: dateFormat,
                })
            )
        } else if (record.type === 'External') {
            // this isn't a tenant, it's an integration
            tiles.push(
                createElement(InstalledExternalIntegrationCard, {
                    key: record.id,
                    integration: record,
                    redirect: redirect,
                })
            )
        }
    })

    return tiles.length > 0 ? tiles : null
}

const TenantList = ({
    externalIntegrationIds = [],
    userName,
    aid,
    namespace,
    ...props
}) => {
    const redirect = useRedirect()
    const singleIntegrationView = useSelector(
        (state) => state.user.singleIntegrationView
    )

    const { data: installedExternalIntegrations } = useGetList(
        'integrations',
        // unpublished integrations can still have tenants!
        {
            filter: {
                approval_status: 'APPROVED',
                type__in: JSON.stringify(['EXTERNAL']),
                with_status: false,
                marketplace_settings__externalId__in: JSON.stringify(
                    externalIntegrationIds
                ),
            },
        }
    )

    const [tenants, setTenants] = useState(undefined)
    const dataProvider = useDataProvider()
    const tenantsCallback = (event) => {
        setTenants(event.payload)
    }

    const userFilter = JSON.stringify([
        `${cleanUserName(userName)}`,
        `${cleanUserName(aid)}`,
    ])

    useEffect(() => {
        dataProvider.subscribe(
            `tenants?connected_users__in=${userFilter}&sort_by=created_date__DESC`,
            tenantsCallback
        )
        return () =>
            dataProvider.unsubscribe(
                `tenants?connected_users__in=${userFilter}&sort_by=created_date__DESC`,
                tenantsCallback
            )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const location = useLocation()
    /* location is used to pass information about the url.
    if it has integration, it means the C2 is redirected via deep linking */
    const [deepLinkIntegrationId, setDeepLinkIntegration] = useState(
        location?.state?.integration
    )

    const deepLinkIntegrationName = useMemo(
        () =>
            tenants?.find(
                (tenant) =>
                    tenant.integration &&
                    tenant.integration.id.toString() === deepLinkIntegrationId
            )?.integration.long_name,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [deepLinkIntegrationId, tenants?.[0]?.name]
    )

    const theme = useThemeConfig()
    const { dateFormat, marketplaceSettings } = theme.configs

    const [searchResults, setSearchResults] = useState(null)
    const muiTheme = useTheme()
    const isLargeMedia = useMediaQuery(muiTheme.breakpoints.up('lg'))

    const getSearchList = useCallback((searchResults) => {
        setSearchResults(searchResults)
        // null out deep link when the user starts typing in the search bar
        setDeepLinkIntegration(null)
    }, [])

    useEffect(() => {
        tenants &&
            genTenantSearchIndex([
                ...tenants,
                ...(installedExternalIntegrations || []),
            ])
        // we can't add tenants as a dependency because this useEffect would fire on every poll
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tenants?.[0]?.name, installedExternalIntegrations])

    const clearSearchList = () => {
        setSearchResults(null)
    }

    const tenantsToDisplay = useMemo(() => {
        if (searchResults) {
            const internalTenants = tenants.filter((tenant) =>
                searchResults.includes(tenant.id.toString())
            )
            const externalTenants = installedExternalIntegrations.filter(
                (integration) =>
                    searchResults.includes(
                        'external-' + integration.id.toString()
                    )
            )
            return [...internalTenants, ...externalTenants]
        }
        // if search results are null, that means the user is not
        // using the search bar, and we want to display all the tenants
        // In case of deep link integrations, we want to show only the tenants of that Integration
        else if (deepLinkIntegrationId) {
            return tenants?.filter(
                (tenant) =>
                    tenant.integration?.id.toString() === deepLinkIntegrationId
            )
        }
        return [...(tenants || []), ...(installedExternalIntegrations || [])]
    }, [
        searchResults,
        tenants,
        deepLinkIntegrationId,
        installedExternalIntegrations,
    ])

    return (
        <Box sx={classes.container} className={'pandium-installed-apps'}>
            {props.noHeaders ? null : (
                <Box sx={classes.header}>
                    <Grid
                        direction="row"
                        container
                        justifyContent="space-between"
                        alignItems="start"
                    >
                        {!singleIntegrationView && (
                            <Grid item className={'pandium-header'}>
                                <Typography
                                    className="pandium-main-text"
                                    sx={classes.title1}
                                    variant="h3"
                                >
                                    {marketplaceSettings.appListTitle ??
                                        'Installed apps'}
                                </Typography>
                                <Typography
                                    className="pandium-sub-text"
                                    variant="subtitle1"
                                >
                                    {marketplaceSettings.appListSubheader}
                                </Typography>
                            </Grid>
                        )}
                        {marketplaceSettings.displaySeparateAppsButton && (
                            <Grid item>
                                <Button
                                    className={'pandium-primary-button'}
                                    variant="contained"
                                    color="primary"
                                    onClick={() => redirect('/integrations')}
                                    sx={classes.headerButton}
                                >
                                    {marketplaceSettings.moreAppsButtonLabel ??
                                        'Get more apps'}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            )}
            <Box sx={classes.installedAppsDiv}>
                {props.noHeaders && (
                    <Typography
                        variant="h5"
                        sx={{ textTransform: 'capitalize' }}
                    >
                        Installed apps{' '}
                    </Typography>
                )}
                {tenants && (
                    <Grid container>
                        {marketplaceSettings.separateApps &&
                            marketplaceSettings.hasInstalledSearchbar && (
                                <Grid item xs={4} style={{ marginTop: '40px' }}>
                                    <SearchBar
                                        handler={getSearchList}
                                        clear={clearSearchList}
                                        placeholder={
                                            marketplaceSettings.tenantSearchbarText ??
                                            ' Search installed apps'
                                        }
                                        ariaLabel="Search installed apps"
                                        isTenantListPage={true}
                                        deepLinkIntegration={
                                            deepLinkIntegrationName
                                        }
                                    />
                                </Grid>
                            )}
                        {deepLinkIntegrationId &&
                            (!marketplaceSettings.separateApps ||
                                (marketplaceSettings.separateApps &&
                                    !marketplaceSettings.hasInstalledSearchbar)) &&
                            !singleIntegrationView && (
                                <Grid container alignItems={'center'}>
                                    <Typography mr={'4px'}>
                                        Showing installed instances of{' '}
                                        {deepLinkIntegrationName}
                                    </Typography>
                                    <Button
                                        sx={{
                                            fontSize: '14px',
                                            color: '#039BE5',
                                            fontWeight: 600,
                                        }}
                                        onClick={() => {
                                            setDeepLinkIntegration(null)
                                            clearSearchList()
                                        }}
                                    >
                                        Show all
                                    </Button>
                                </Grid>
                            )}
                    </Grid>
                )}
            </Box>
            {tenants && installedExternalIntegrations ? (
                tenants.length ||
                Object.keys(installedExternalIntegrations).length ? (
                    <Grid container spacing={isLargeMedia ? 4 : 2}>
                        {isEmpty(tenantsToDisplay) ? (
                            <Typography color={'textSecondary'}>
                                {marketplaceSettings.tenantNullSearchMessage ??
                                    'No results found'}
                            </Typography>
                        ) : (
                            renderTenantCards(
                                tenantsToDisplay,
                                redirect,
                                dateFormat
                            )
                        )}
                    </Grid>
                ) : (
                    <Typography
                        color={'textSecondary'}
                        style={{ marginTop: '40px' }}
                    >
                        You have no apps installed.
                    </Typography>
                )
            ) : (
                <CircularProgress />
            )}
        </Box>
    )
}

const mapStateToProps = (state) => ({
    userName: state.user.userName,
    aid: state.user.aid,
    externalIntegrationIds: state.user.externalIntegrations,
    namespace: state.user.namespace,
    user: state.user,
})

export default connect(mapStateToProps)(TenantList)
