import React, { Component } from 'react'
import {
    CircularProgress,
    Typography,
    Divider,
    Button,
    Grid,
    Box,
} from '@mui/material'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { useCreate, useRedirect, useNotify, useDataProvider } from 'react-admin'
import { connect, useSelector } from 'react-redux'
import ImageSlider from '../../components/ImageSlider'
import {
    cleanUserName,
    isBackgroundConnect,
} from '../../components/componentHelpers'
import { get, includes, isEmpty } from 'lodash-es'
import { useLocation } from 'react-router'
import { trackUmamiEvent } from '@thefront/pandipackV2'
import { useNavigate } from 'react-router-dom'
import { useThemeConfig } from '../../themeConfig'
import { ContentBlock } from '../../components/common'

const basename = window.location.pathname.split('/')[1]
const CREATED_NOTIFICATION = 'ra.notification.created'

const FullWidth = () => {
    const theme = useThemeConfig()
    return theme?.configs?.marketplaceSettings?.fullWidthMarketplace
}

export const BackButton = () => {
    const theme = useThemeConfig()
    const redirect = useRedirect()
    const { marketplaceSettings } = theme.configs
    return (
        <Button
            className={'pandium-back-button'}
            size="small"
            sx={(theme) => ({
                fontSize: '16px',
                color: theme.palette.secondary.main,
                marginBottom: '32px',
            })}
            onClick={() => redirect('/integrations')}
        >
            <KeyboardArrowLeftIcon fontSize="small" />{' '}
            {marketplaceSettings.backButtonLabel ?? 'All integrations'}
        </Button>
    )
}

export const InstancesButton = ({ integrationId, navigate }) => {
    const theme = useThemeConfig()
    const { marketplaceSettings } = theme.configs

    return (
        <Button
            size="small"
            sx={{
                fontSize: '12px',
                color: '#039BE5',
                fontWeight: 600,
                marginLeft: '12px',
            }}
            onClick={() =>
                navigate(
                    marketplaceSettings.separateApps
                        ? '/tenants'
                        : '/integrations',
                    {
                        state: {
                            integration: `${integrationId}`,
                        },
                    }
                )
            }
        >
            {marketplaceSettings.instancesButtonLabel ?? 'App instances'}
        </Button>
    )
}

const mapDescriptions = (descriptions) => {
    return descriptions && descriptions.length
        ? descriptions.map((obj, index) => (
              <span key={index}>
                  <Typography
                      variant="subtitle1"
                      sx={{
                          margin: '20px 0',
                          fontSize: '16px',
                          fontWeight: 'bold',
                      }}
                  >
                      {obj.Subtitle}
                  </Typography>
                  <Typography variant="body1" margin="8px 0">
                      <ContentBlock record={obj} source="Description" />
                  </Typography>
              </span>
          ))
        : null
}

const getIntegrationMedia = (integrationMedia) => {
    return integrationMedia && integrationMedia.length
        ? integrationMedia.map((obj) => obj.src)
        : null
}

const useExternalButtonAttributes = (
    integration,
    isExternalGreishInstalled,
    marketplaceSettings
) => {
    const target = integration?.marketplaceSettings?.linkTarget ?? '_blank'
    const href = integration?.marketplaceSettings?.externalURL
    const text = isExternalGreishInstalled
        ? marketplaceSettings?.installedExternalIntegrationButton
        : marketplaceSettings?.externalIntegrationButton

    return { target, href, text }
}

const ExternalConnectButton = ({
    integration,
    isExternalGreishInstalled = false,
}) => {
    const theme = useThemeConfig()
    const { target, href, text } = useExternalButtonAttributes(
        integration,
        isExternalGreishInstalled,
        theme?.configs?.marketplaceSettings
    )

    return (
        <Button
            className={'pandium-install-button'}
            color="primary"
            variant="contained"
            target={target}
            href={href}
            rel="noopener noreferrer"
            children={text}
            sx={{
                width: '100%',
                height: '44px',
            }}
        />
    )
}

export const ConnectAppButton = ({
    handleSave,
    integrationRelease,
    integrationReleaseChannel,
    hasReleases,
}) => {
    const theme = useThemeConfig()
    const { marketplaceSettings } = theme.configs
    const connectable =
        hasReleases &&
        ((integrationRelease && integrationRelease !== -1) ||
            integrationReleaseChannel === 'Latest')
    return (
        <Button
            className={'pandium-install-button'}
            disabled={!connectable}
            color="primary"
            variant="contained"
            onClick={handleSave}
            sx={{
                width: '100%',
                height: '44px',
                textTransform: 'uppercase',
            }}
        >
            {connectable
                ? marketplaceSettings.connectButtonLabel || 'Connect app'
                : 'Contact admin to set default release'}
        </Button>
    )
}

export const saveTenant = (
    tenantValues,
    connectors,
    theme,
    create,
    user,
    integration,
    notify,
    redirectTo,
    dataProvider
) => {
    const onSuccess = (newRecord) => {
        trackUmamiEvent(
            `user: ${user.userName}, ${integration.name}`,
            'Install',
            newRecord.id,
            'tenants',
            user.userID
        )

        if (
            integration.connectors?.find((connector) =>
                isBackgroundConnect(connector, theme, connectors)
            )
        ) {
            handleBackgroundConnect(
                user,
                newRecord,
                integration,
                notify,
                redirectTo,
                dataProvider
            )
        } else {
            notify('Tenant created successfully', 'info')
            redirectTo('/tenants/' + newRecord.id + '/edit')
        }
    }

    const onError = (error) => {
        console.error(error)
        // Optionally notify the user about the error
    }

    // Call the create function to save the tenant
    create('tenants', { data: tenantValues }, { onSuccess, onError })
}

export const useHandleSave = (
    integration,
    integrationId,
    connectors,
    theme,
    user
) => {
    const [create] = useCreate()
    const notify = useNotify()
    const redirectTo = useRedirect()
    const dataProvider = useDataProvider()

    // Construct tenant values based on props
    const tenantValues = {
        name: cleanUserName(user.userName),
        integration: integrationId,
        paused: get(integration, 'marketplaceSettings.paused', true),
        userSchedule: integration.marketplaceSettings.schedule || '0 1 * * *',
        source: `imp-${basename}`,
        connected_users: {
            [basename]: { username: cleanUserName(user.userName) },
            pandium: { username: cleanUserName(user.userName) },
        },
    }

    const handleSave = () => {
        saveTenant(
            tenantValues,
            connectors,
            theme,
            create,
            user,
            integration,
            notify,
            redirectTo,
            dataProvider
        )
    }

    return handleSave
}

const handleBackgroundConnect = (
    user,
    tenant,
    integration,
    notify,
    redirectTo,
    dataProvider
) => {
    const connector = integration.connectors.find((connector) =>
        connector.name.includes(basename)
    )
    dataProvider
        .AUTHOR('author/background_connect', {
            data: {
                client_id: user.userName,
                integration_name: get(integration, 'name'),
                connector_name: get(connector, 'name'),
                tenant: tenant,
                xti: user.xti,
            },
        })
        .then((response) => {
            notify(CREATED_NOTIFICATION, 'info')
            redirectTo('/tenants/' + tenant.id + '/edit')
        })
        .catch((error) => {
            notify('An error occurred please try again', 'warning')
            dataProvider.delete('tenants', { id: tenant.id })
        })
}

const SaveTenantButton = ({
    integration,
    connectors,
    integrationRelease,
    integrationReleaseChannel,
    hasReleases,
    isExternalGreishInstalled,
    integrationId,
    user,
}) => {
    const theme = useThemeConfig()
    const handleSave = useHandleSave(
        integration,
        integrationId,
        connectors,
        theme,
        user
    )

    return integration.type !== 'External' ? (
        <ConnectAppButton
            handleSave={handleSave}
            integrationRelease={integrationRelease}
            integrationReleaseChannel={integrationReleaseChannel}
            hasReleases={hasReleases}
        />
    ) : (
        <ExternalConnectButton
            isExternalGreishInstalled={isExternalGreishInstalled}
            integration={integration}
        />
    )
}

const IntegrationShowTop = ({
    integration,
    connectors,
    integrationId,
    integrationRelease,
    integrationReleaseChannel,
    hasReleases,
    user,
    externalIntegrations,
    installedTenants,
    singleIntegrationView,
    navigate,
}) => {
    const pandiumTheme = useThemeConfig()
    const { marketplaceSettings } = pandiumTheme.configs

    const slides = getIntegrationMedia(
        get(integration, 'marketplaceSettings.media')
    )
    const logo =
        integration.marketplaceSettings.primaryLogo?.src ||
        integration.marketplaceSettings.primaryLogo
    const TenantInfoBox = ({ installedTenants, integration }) => (
        <Box sx={styles.tenantInfoBox} className={'pandium-instances'}>
            <Typography variant="subtitle2">
                You have {installedTenants}
                {installedTenants === 1 ? ' instance ' : ' instances '}
                of {integration.longName} installed
            </Typography>
            {false && (
                <InstancesButton
                    integrationId={integrationId}
                    navigate={navigate}
                />
            )}
        </Box>
    )

    // CSS-in-JS styles
    const styles = {
        contentWrapper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingBottom: '12px',
        },
        tenantInfoBox: {
            padding: '6px 20px',
            marginTop: '12px',
            backgroundColor: '#F5F5F5',
            border: '1px solid #DDDDDD',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            borderRadius: '4px',
            width: '100%',
            boxSizing: 'border-box',
        },
        logoBox: {
            borderRadius: '16px',
            border: '1px solid rgba(226, 226, 226, 1)',
            opacity: 1,
            backgroundColor: 'rgba(249, 250, 251, 1)',
            display: 'flex',
            alignItems: 'center',
            padding: '16px',
            marginBottom: '16px',
            height: '88px',
            alignSelf: 'flex-start',
        },
        logo: {
            borderRadius: '16px',
            width: '72px',
            height: '72px',
            marginRight: '8px',
            objectFit: 'contain',
        },
        longName: (theme) => ({
            fontSize: '30px',
            fontWeight: 400,
            letterSpacing: '0px',
            textAlign: 'left',
            lineHeight: '38px',
            fontFamily: theme.typography.fontFamily,
            marginRight: '30px',
        }),
        linkButtonWrapper: {
            display: 'flex',
            flexWrap: 'nowrap',
            width: '100%',
            height: '45px',
            marginTop: '13px',
        },
        linkButton: {
            width: '33.3333%',
            boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
            opacity: 1,
            textTransform: 'uppercase',
        },
    }

    return (
        <>
            <Grid item xs={12}>
                {!singleIntegrationView && <BackButton />}
            </Grid>
            <Grid
                className={'pandium-app-details'}
                item
                xs={6}
                container
                sx={{
                    display: 'flex',
                    height: slides ? '500px' : null,
                }}
            >
                <Box sx={styles.logoBox} className={'pandium-logo-box'}>
                    <Box
                        className={'pandium-logo'}
                        component={'img'}
                        src={logo}
                        alt="Logo"
                        sx={styles.logo}
                    />
                    <Typography variant="subtitle1" sx={styles.longName}>
                        {integration.longName}
                    </Typography>
                </Box>
                <Grid
                    item
                    xs={12}
                    height="270px"
                    sx={styles.contentWrapper}
                    className={'pandium-text'}
                >
                    <ContentBlock
                        record={integration}
                        source="marketplaceSettings.detailShortDescription"
                    />
                    {installedTenants > 0 && (
                        <TenantInfoBox
                            installedTenants={installedTenants}
                            integration={integration}
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <SaveTenantButton
                        integrationId={integrationId}
                        connectors={connectors}
                        integration={integration}
                        integrationRelease={integrationRelease}
                        integrationReleaseChannel={integrationReleaseChannel}
                        hasReleases={hasReleases}
                        user={user}
                        isExternalGreishInstalled={includes(
                            externalIntegrations,
                            get(integration, 'marketplaceSettings.externalId')
                        )}
                    />
                </Grid>
                <Box sx={styles.linkButtonWrapper}>
                    {[
                        {
                            urlPath: 'devURL',
                            textPath: 'devUrlText',
                            defaultText: 'Developer',
                        },
                        {
                            urlPath: 'docURL',
                            textPath: 'docUrlText',
                            defaultText: 'Documentation',
                        },
                        {
                            urlPath: 'supportURL',
                            textPath: 'supportUrlText',
                            defaultText: 'Get support',
                        },
                    ].map((linkData, index) => (
                        <Button
                            className={'pandium-link-button'}
                            key={linkData.urlPath}
                            variant="outlined"
                            color="secondary"
                            sx={{
                                ...styles.linkButton,
                                marginRight: index !== 2 ? '12px' : '0',
                            }}
                            onClick={() =>
                                window.open(
                                    integration.marketplaceSettings[
                                        linkData.urlPath
                                    ],
                                    '_blank'
                                )
                            }
                            disabled={
                                !integration.marketplaceSettings[
                                    linkData.urlPath
                                ]
                            }
                        >
                            {marketplaceSettings[linkData.textPath] ||
                                linkData.defaultText}
                        </Button>
                    ))}
                </Box>
            </Grid>
            <Grid item xs={6}>
                <ImageSlider images={slides || [logo]} makeGray={!slides} />
            </Grid>
        </>
    )
}
export class IntegrationShow extends Component {
    constructor(props) {
        super(props)
        this.state = {
            integration: {},
            connectors: undefined,
            integrationRelease: undefined,
            integrationReleaseChannel: undefined,
            installedTenants: 0,
            loading: true,
        }
    }

    componentDidMount() {
        const dataProvider = this.props.dataProvider
        Promise.all([
            dataProvider.getOne('integrations', {
                id: this.props.integrationId,
            }),
            dataProvider.getList('integrationreleases', {
                pagination: { page: 1, perPage: 500 },
                sort: { field: 'created_date', order: 'DESC' },
                filter: {
                    integration_id: this.props.integrationId,
                },
            }),
            dataProvider.getList('tenants', {
                pagination: { page: 1, perPage: 100 },
                sort: { field: 'created_date', order: 'DESC' },
                filter: {
                    connected_users__in: JSON.stringify([
                        cleanUserName(this.props.user.userName),
                        cleanUserName(this.props.user.aid),
                    ]),
                    integration_id: this.props.integrationId,
                },
            }),
        ])
            .then((res) => {
                const integration = res[0].data

                // get list of connector names to filter GET /connectors call
                const connectorList = get(integration, 'connectors')
                const connectorIds = connectorList?.map((connector) => {
                    return connector.name
                })

                return connectorIds?.length
                    ? Promise.all([
                          ...res,
                          dataProvider.getList('connectors', {
                              pagination: { page: 1, perPage: 100 }, // pagination and sorting are required for the getList call, but aren't actually being used
                              sort: { field: 'created_date', order: 'DESC' },
                              filter: {
                                  id: connectorIds,
                              },
                          }),
                      ])
                    : res
            })
            .then((res) => {
                const integration = res[0].data
                const integrationReleases = res[1].data
                const tenants = res[2].data
                const installedTenants = tenants.length
                const connectors = res[3]?.data ?? []

                this.setState({
                    integration,
                    connectors,
                    installedTenants,
                    hasReleases: !isEmpty(integrationReleases),
                    integrationRelease: integration.defaultReleaseId,
                    integrationReleaseChannel:
                        integration.defaultReleaseChannel,
                    loading: false,
                })
            })
            .catch((err) => {
                console.error(err)
                this.setState({
                    loading: false,
                })
            })
    }

    render() {
        const {
            integrationId,
            user,
            externalIntegrations,
            connectBackground,
            disconnectRequest,
            dataProvider,
            redirect,
            navigate,
            singleIntegrationView,
            ...props
        } = this.props

        const {
            integration,
            connectors,
            integrationRelease,
            integrationReleaseChannel,
            installedTenants,
            hasReleases,
            loading,
        } = this.state

        const breakpointStyle = (theme) => ({
            width: '776px',
            [theme.breakpoints.up('sm')]: {
                width: '896px',
            },
            [theme.breakpoints.up('md')]: {
                width: '960px',
            },
            [theme.breakpoints.up('lg')]: {
                width: '1120px',
            },
            [theme.breakpoints.up('xl')]: {
                width: '1280px',
            },
        })

        // The user has not installed an tenants and is using the connect anywhere feature (direct jwt access)
        const isSingleIntegrationViewInitVisit =
            installedTenants === 0 && singleIntegrationView

        // If there are no tenants installed and directly acceced with a jwt, create a new tenant and navigate to the edit tenant page
        if (isSingleIntegrationViewInitVisit && !loading) {
            const newTenantValues = {
                name: cleanUserName(user.userName),
                integration: integrationId,
                paused: get(integration, 'marketplaceSettings.paused', true),
                userSchedule:
                    integration.marketplaceSettings.schedule || '0 1 * * *',
                source: `imp-${basename}`,
                connected_users: {
                    [basename]: {
                        username: cleanUserName(user.userName),
                    },
                    pandium: { username: cleanUserName(user.userName) },
                },
            }
            saveTenant(
                newTenantValues,
                connectors,
                this.props.create,
                user,
                integration,
                this.props.notify,
                redirect,
                dataProvider
            )
        }

        return !isSingleIntegrationViewInitVisit && !loading ? (
            <Box
                sx={
                    FullWidth
                        ? { width: '100%' }
                        : (theme) => breakpointStyle(theme)
                }
                className={`pandium-integration-detail pandium-${integration.name}-detail`}
            >
                <Grid container columnSpacing={12} justifyContent="center">
                    <IntegrationShowTop
                        redirect={redirect}
                        integration={integration}
                        connectors={connectors}
                        props={props}
                        integrationId={integrationId}
                        integrationRelease={integrationRelease}
                        integrationReleaseChannel={integrationReleaseChannel}
                        hasReleases={hasReleases}
                        user={user}
                        externalIntegrations={externalIntegrations}
                        connectBackground={connectBackground}
                        disconnectRequest={disconnectRequest}
                        installedTenants={installedTenants}
                        navigate={navigate}
                    />

                    <Grid item xs={12}>
                        <Divider
                            sx={{
                                margin: '48px 0',
                                height: '2px',
                                width: '100%',
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box width="100%">
                            {get(
                                integration,
                                'marketplaceSettings.longDescriptions'
                            ).length !== 0 && (
                                <Typography variant="h5">
                                    About {integration.longName}
                                </Typography>
                            )}
                            {mapDescriptions(
                                get(
                                    integration,
                                    'marketplaceSettings.longDescriptions'
                                )
                            )}
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        ) : (
            <CircularProgress />
        )
    }
}

function getIntegrationIDFromPath(pathname) {
    const splitPath = pathname.split('/')
    return splitPath[splitPath.length - 1]
}

const mapStateToProps = (state, props) => {
    return {
        user: state.user,
        externalIntegrations: state.user.externalIntegrations,
    }
}

function withDataProvider(ClassComponent) {
    return function WrappedComponent(props) {
        const dataProvider = useDataProvider()
        const location = useLocation()
        const integrationId = getIntegrationIDFromPath(location.pathname)
        const navigate = useNavigate()
        const redirect = useRedirect()
        const [create] = useCreate()
        const notify = useNotify()

        const singleIntegrationView = useSelector(
            (state) => state.user.singleIntegrationView
        )

        return (
            <ClassComponent
                dataProvider={dataProvider}
                redirect={redirect}
                integrationId={parseInt(integrationId)}
                navigate={navigate}
                create={create}
                notify={notify}
                singleIntegrationView={singleIntegrationView}
                {...props}
            />
        )
    }
}

export default withDataProvider(connect(mapStateToProps)(IntegrationShow))
